import { PageProps } from '@lp-src/utils/local-types';
import { appPaths } from '@lp-src/utils/path';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { ArticleDetail, ArticlePageData } from '@lp-root/src/components/org.article-detail/article-detail.component';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { Col, Grid, Row, Separator } from '@web/atomic';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { GatsbyButton } from '@components/atm.button/button.component';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';

export interface InternalSessionDetailTemplateProps {
  id: string;
}

/**
 * this template is used on articles available only inside the chatfuel webview
 * i.e., from files located here: `packages/app.landing-page/_cms/internal`
 * @param props
 */
const InternalSessionDetailTemplate: React.FunctionComponent<PageProps<InternalSessionDetailTemplateProps>> = (props) => {
  const data = props.data.markdownRemark;
  const siteUrl = props.data.site.siteMetadata.siteUrl;

  const pageData: ArticlePageData = {
    title: data.frontmatter.title,
    subtitle: data.frontmatter.description,
    headings: data.headings,
    publishedDate: parseToDate(data.frontmatter.date),
    htmlAst: data.htmlAst,
    timeToRead: data.timeToRead,
    hideSEO: true,
    hideTopRow: true,
    hideShare: true,
    hideTopImage: !data.frontmatter.showTopImage,
    hideTableOfContents: !data.frontmatter.showTableOfContents,
    seo: {
      title: data.frontmatter.seoTitle || data.frontmatter.title,
      description: data.frontmatter.seoDescription || data.excerpt,
      imageUrl: data.frontmatter.img,
      imageAlt: data.frontmatter.imgAlt,
      article: {
        published_time: parseToDate(data.frontmatter.date),
        autor: ['Vigilantes do Sono'],
        section: 'Artigos',
        tag: [],
      },
    },
  };

  const { close, loading } = useCloseMessengerModalCallback();
  return (
    <WebviewLayout>
      <Grid>
        <ArticleDetail
          data={pageData}
          slug={props.pageContext.id}
          canonicalUrl={getCanonicalUrl(siteUrl, appPaths.blog.path, props.data.markdownRemark.frontmatter.path)}
        />
        <Row center={'xs'}>
          <Col xs={12} md={7}>
            <GatsbyButton onClick={close} loading={loading} expanded>
              Fechar
            </GatsbyButton>
            <Separator />
          </Col>
        </Row>
      </Grid>
    </WebviewLayout>
  );
};

export default InternalSessionDetailTemplate;

export const query = graphql`
  query InternalSessionBySlug($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      timeToRead
      html
      excerpt
      htmlAst
      headings {
        depth
        value
      }
      frontmatter {
        date
        path
        title
        description
        seoTitle
        seoDescription
        img
        imgAlt
        showTopImage
        showTableOfContents
      }
    }
  }
`;
